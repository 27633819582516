import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["typeInput", "customInput", "formulaInput", "refInput"];
  static values = {
    formulaVariablesTemplate: String,
  };

  connect() {
    this.typeInputChangeHandler = this.onTypeInputChange.bind(this);
    this.typeInputTarget.addEventListener("select:change", this.typeInputChangeHandler);

    if (this.hasFormulaInputTarget) {
      this.formulaInputChangeHandler = this.onFormulaInputChange.bind(this);
      this.formulaInputTarget.addEventListener("select:change", this.formulaInputChangeHandler);
    }
  }

  disconnect() {
    this.typeInputTarget.removeEventListener("select:change", this.typeInputChangeHandler);

    if (this.hasFormulaInputTarget) {
      this.formulaInputTarget.removeEventListener("select:change", this.formulaInputChangeHandler);
    }
  }

  onTypeInputChange(e) {
    if (this.element.dataset.valueTypeChange == "reload") {
      this.reloadFrameWithParam(e);
    } else {
      this.switchInput(e);
    }
  }

  switchInput(e) {
    const selectedType = e.detail.value;

    switch (selectedType) {
      case "amount":
        this.showInput(this.customInputTarget, true);
        if (this.hasFormulaInputTarget) this.hideInput(this.formulaInputTarget);
        if (this.hasRefInputTarget) this.hideInput(this.refInputTarget);
        break;

      case "amount_ref":
        this.hideInput(this.customInputTarget, true);
        if (this.hasFormulaInputTarget) this.hideInput(this.formulaInputTarget);
        if (this.hasRefInputTarget) this.showInput(this.refInputTarget);
        break;

      case "formula":
        this.hideInput(this.customInputTarget, true);
        if (this.hasFormulaInputTarget) this.showInput(this.formulaInputTarget);
        if (this.hasRefInputTarget) this.hideInput(this.refInputTarget);
        break;
    }
  }

  reloadFrameWithParam(e) {
    const hostedFrame = e.target.closest("turbo-frame");
    const selectedType = e.detail.value;

    if (hostedFrame) {
      let frameUrl = new URL(hostedFrame.getAttribute("src"));
      let params = new URLSearchParams(frameUrl.search);

      params.set("value_type", selectedType);
      params.delete("formula_id");

      frameUrl.search = params.toString();

      // hostedFrame.src = "";
      hostedFrame.src = frameUrl.toString();
    }
  }

  hideInput(input) {
    if (!input.classList.contains("hidden")) {
      input.classList.add("hidden");
      input.classList.remove("flex");
    }
  }

  showInput(input) {
    if (input.classList.contains("hidden")) {
      input.classList.remove("hidden");
      input.classList.add("flex");
    }
  }

  async onFormulaInputChange(e) {
    const selectedFormulaId = e.detail.value;
    const hostedFrame = e.target.closest("turbo-frame");

    if (hostedFrame) {
      let frameUrl = new URL(hostedFrame.getAttribute("src"));
      let params = new URLSearchParams(frameUrl.search);

      params.set("formula_id", selectedFormulaId);

      frameUrl.search = params.toString();

      hostedFrame.src = "";
      hostedFrame.src = frameUrl.toString();
    }
  }
}
