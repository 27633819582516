import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.uploadCompleteHandler = this.onUploadComplete.bind(this);
    this.element.addEventListener("upload:complete", this.uploadCompleteHandler);
  }

  disconnect() {
    this.element.removeEventListener("upload:complete", this.uploadCompleteHandler);
  }

  onUploadComplete(event) {
    // console.log("upload complete", event);
    const frame = this.element.closest("turbo-frame");
    frame.reload();
  }
}
