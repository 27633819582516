import { StreamActions } from "@hotwired/turbo";
import _ from "lodash";

StreamActions.raise_event = function () {
  const detail = this.getAttribute("detail");
  const eventName = this.getAttribute("event");
  const eventDetail = JSON.parse(detail);
  const receivers = this.getAttribute("receivers").split(",");

  console.log("raising event", eventName, receivers);

  if (_.isEmpty(receivers)) {
    document.dispatchEvent(
      new CustomEvent(eventName, {
        bubbles: true,
        cancelable: true,
        detail: eventDetail,
      }),
    );
  } else {
    receivers.forEach((elId) => {
      const targetElement = document.getElementById(elId);

      if (targetElement) {
        targetElement.dispatchEvent(
          new CustomEvent(eventName, {
            bubbles: true,
            cancelable: true,
            detail: eventDetail,
          }),
        );
      }
    });
  }
};
