import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select/dist/esm/tom-select.complete";
import Handlebars from "handlebars";

export default class extends Controller {
  static targets = ["input", "optionTmpl", "itemTmpl", "optgroupTmpl", "noResultsTmpl"];
  static values = {
    opened: {
      type: Boolean,
      default: false,
    },
  };

  connect() {
    const config = JSON.parse(this.element.dataset.config);
    const searchable = config.searchable;

    const defaultConfig = {
      copyClassesToDropdown: false,
      lockOptgroupOrder: true,
      onInitialize: () => {
        this.inputTarget.classList.remove("init-select");
      },
      onDropdownOpen: () => {
        this.openedValue = true;
        this.checkNoOptions();
      },
      onDropdownClose: () => {
        this.openedValue = false;
      },
      onChange: (value) => {
        this.dispatch("change", { detail: { value: value } });
        this.checkNoOptions();
      },
      render: this.buildRenderers(),
    };

    let pluginConfig = { plugins: [] };

    // if (!config.create || searchable) {
    if (searchable) {
      pluginConfig.plugins.push("dropdown_input");
    } else {
      defaultConfig.controlInput = null;
    }

    if (this.inputTarget.multiple) {
      pluginConfig.plugins.push("remove_button");
    }

    this.tomselect = new TomSelect(this.inputTarget, { ...defaultConfig, ...pluginConfig, ...config });

    this.checkNoOptions();

    if (this.openedValue) {
      this.tomselect.open();
    }
  }

  checkNoOptions() {
    const optionsCount = Object.keys(this.tomselect.options).length;
    const selectedCount = this.tomselect.getValue().length;
    const dropdown = this.tomselect.dropdown_content;
    const noMoreOptionsMessage = dropdown.querySelector(".no-more-options");

    if (selectedCount === optionsCount) {
      // All options are selected
      if (!noMoreOptionsMessage) {
        const message = document.createElement("div");
        message.className = "no-more-options text-base-500 py-2 px-3 text-xs italic";
        message.textContent = "No options available.";
        if (dropdown) {
          dropdown.appendChild(message);
        }
      }
    } else {
      // Remove the "no more options" message if it exists
      if (noMoreOptionsMessage) {
        noMoreOptionsMessage.remove();
      }
      // Force dropdown to re-render options
      // this.tomselect.refreshOptions();
    }
  }

  buildRenderers() {
    let renderers = {};

    if (this.hasOptionTmplTarget) {
      renderers.option = (data) => {
        return this.compileTemplate(this.optionTmplTarget, data);
      };
    }

    if (this.hasItemTmplTarget) {
      renderers.item = (data) => {
        return this.compileTemplate(this.itemTmplTarget, data);
      };
    }

    if (this.hasOptgroupTmplTarget) {
      renderers.optgroup_header = (data) => {
        return this.compileTemplate(this.optgroupTmplTarget, data);
      };
    }

    if (this.hasNoResultsTmplTarget) {
      renderers.no_results = (data) => {
        return this.compileTemplate(this.noResultsTmplTarget, data);
      };
    }

    return renderers;
  }

  compileTemplate(source, data) {
    const template = Handlebars.compile(source.innerHTML);
    return template(data);
  }
}
