import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frame", "item"];

  connect() {
    this.frameTarget.addEventListener("turbo:frame-load", this.updateActiveLink.bind(this));
  }

  updateActiveLink(event) {
    const frame = event.target;

    if (frame.id !== this.frameTarget.id) return;

    const currentUrl = new URL(frame.src, window.location.origin).pathname;

    this.itemTargets.forEach((link) => {
      if (new URL(link.href, window.location.origin).pathname === currentUrl) {
        link.classList.add("active"); // Your active class
      } else {
        link.classList.remove("active");
      }
    });
  }
}
