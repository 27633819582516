import { Controller } from "@hotwired/stimulus";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import Dashboard from "@uppy/dashboard";
import { toast } from "../stream_actions/toast";
import { merge } from "lodash";

/**
 * Upload controller setup:
 * <button data-controller="upload"
 *         data-previewer="previewer-id"
 *         data-max-files="1"
 *         data-file-types="image/*"
 *         data-upload-url="...">
 *   Upload trigger
 * </button>
 *
 */
export default class extends Controller {
  // static targets = ["trigger"];

  connect() {
    this.previewer = document.getElementById(this.element.dataset.previewer);
    this.uploadUrl = this.element.dataset.uploadUrl;

    this._initUppy();
  }

  disconnect() {
    this.uppy.destroy();
  }

  _getMaxFiles() {
    return 5;
  }

  _getFileTypes() {
    return [];
  }

  _initUppy() {
    const coreOptions = this._getCoreOptions();
    const dashboardOptions = this._getDashboardOptions();
    const xhrUploadOptions = this._getXHRUploadOptions();

    this.uppy = new Uppy(coreOptions).use(Dashboard, dashboardOptions).use(XHRUpload, xhrUploadOptions);

    this.uppy.on("upload-success", this._handleUploadSuccess.bind(this));
    this.uppy.on("upload-error", this._handleUploadError.bind(this));
    this.uppy.on("file-added", this._handleFileAdded.bind(this));
    this.uppy.on("complete", this._handleComplete.bind(this));
  }

  _handleComplete(result) {
    this.dispatch("complete", { target: this.previewer, detail: { result: result } });
    this.uppy.clear();
  }

  _handleUploadSuccess(file, response) {
    toast(`File ${file.name} uploaded successfully!`, "success");

    const uploadedFile = merge(file, response.body);
    this.dispatch("success", { target: this.previewer, detail: { file: uploadedFile } });
  }

  _handleUploadError(file, error, response) {
    // console.log(`Error with file: ${file.id}, reason: ${error}`);
    // console.log(file);
    toast(`File ${file.name} uploaded failed!`, "error");
    this.dispatch("error", { target: this.previewer, detail: { file: file } });
  }

  _handleFileAdded(file) {
    this.uppy.setFileMeta(file.id, {
      authenticity_token: this._getCsrfToken(),
    });
  }

  _getCoreOptions() {
    let restrictions = {
      maxFileSize: this._megabyteToByte(5),
      maxNumberOfFiles: this._getMaxFiles(),
    };

    if (this._getFileTypes().length > 0) {
      restrictions["allowedFileTypes"] = this._getFileTypes();
    }

    return {
      restrictions: restrictions,
    };
  }

  _getDashboardOptions() {
    return {
      target: "#uppy-dashboard",
      inline: false,
      trigger: this.element,
      height: 320,
      browserBackButtonClose: true,
      showProgressDetails: true,
      closeAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
      metaFields: [
        { id: "name", name: "Name", value: "", placeholder: "Set file name" },
        { id: "comment", name: "Comment", value: "", placeholder: "Add comment" },
      ],
      locale: {
        strings: {
          dropPasteFiles: "Drop files here or %{browseFiles}",
          browseFiles: "browse file",
        },
      },
    };
  }

  _getXHRUploadOptions() {
    return {
      endpoint: this.uploadUrl,
      fieldName: "file",
      formData: true,
      allowedMetaFields: ["authenticity_token", "name", "comment"],
    };
  }

  _getCsrfToken() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content");
  }

  _megabyteToByte(size) {
    return size * 1024 * 1024;
  }
}
