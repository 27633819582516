import { Controller } from "@hotwired/stimulus";
import { getDialogTemplate } from "../utils/template";
import { gsap } from "gsap";
import { useMatchMedia } from "stimulus-use";

export default class extends Controller {
  connect() {
    useMatchMedia(this, {
      mediaQueries: {
        sm: "(min-width: 1280px)",
      },
    });

    this.createDialogHandler = this.createDialog.bind(this);
    document.addEventListener("dialog:create", this.createDialogHandler);
  }

  disconnect() {
    document.removeEventListener("dialog:create", this.createDialogHandler);
  }

  createDialog(e) {
    const spec = {
      id: e.detail.id,
      show: true,
      size: e.detail.size || "sm",
      frameId: `${e.detail.id}-frame`,
      href: e.detail.href,
      type: e.detail.type,
    };

    const dialog = getDialogTemplate(spec);

    // Append the modal to the body
    document.body.insertAdjacentHTML("beforeend", dialog);
  }

  toggleSidebar(e) {
    const sidebar = document.getElementById("sidebar");
    const collapsed = sidebar.dataset.collapsed === "true";
    const trigger = e.currentTarget;

    if (collapsed) {
      gsap.to(sidebar, {
        opacity: 1,
        x: "0%",
        duration: 0.3,
        width: 240,
        onComplete: () => {
          sidebar.dataset.collapsed = false;
          trigger.classList.remove("t-sidebar-collapsed");
        },
      });
    } else {
      gsap.to(sidebar, {
        opacity: 0,
        x: "-100%",
        duration: 0.3,
        width: 0,
        onComplete: () => {
          sidebar.dataset.collapsed = true;
          trigger.classList.add("t-sidebar-collapsed");
        },
      });
    }
  }
}
