import { Controller } from "@hotwired/stimulus";
import _ from "lodash";

export default class extends Controller {
  connect() {
    this.setElementId();
  }

  disconnect() {
    _.remove(this.collection(), (item) => item.id === this.element.id);
  }

  setElementId() {
    if (!this.element.id) {
      this.element.id = this.generateElementId();
    }
  }

  generateElementId() {
    return `${this.prefix()}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }

  prefix() {
    throw new Error(`prefix() must be implemented in the derived controller.`);
  }

  collection() {
    throw new Error(`collection() must be implemented in the derived controller.`);
  }
}
