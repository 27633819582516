import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.messageCreatedHandler = this.onMessageCreated.bind(this);
    this.messageReadHandler = this.onMessageRead.bind(this);

    this.element.addEventListener("message:created", this.messageCreatedHandler);
    this.element.addEventListener("message:read", this.messageReadHandler);
  }

  disconnect() {
    this.element.removeEventListener("message:created", this.messageCreatedHandler);
    this.element.removeEventListener("message:read", this.messageReadHandler);
  }

  onMessageCreated(e) {
    const { conversation, sender_id } = e.detail;

    if (this.currentUserId !== sender_id) {
      const itemId = conversation.item_dom_id;
      const itemElement = document.getElementById(itemId);

      if (conversation.unread_count > 0) {
        itemElement.querySelector(".unread-count").classList.remove("hidden");
        itemElement.querySelector(".unread-count").textContent = conversation.unread_count;
      }
    }
  }

  onMessageRead(e) {
    console.log(e.detail);
    const { conversation, receiver_id } = e.detail;

    if (this.currentUserId === receiver_id) {
      const itemId = conversation.item_dom_id;
      const itemElement = document.getElementById(itemId);

      if (conversation.unread_count > 0) {
        itemElement.querySelector(".unread-count").classList.remove("hidden");
      } else {
        itemElement.querySelector(".unread-count").classList.add("hidden");
      }

      itemElement.querySelector(".unread-count").textContent = conversation.unread_count;
    }
  }

  get currentUserId() {
    return document.querySelector("meta[name='current-user']").getAttribute("content");
  }
}
