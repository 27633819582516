import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";

// Collapsible
export default class extends Controller {
  static targets = ["content"];
  static values = {
    show: { type: Boolean, default: false },
  };

  connect() {
    this.showing = this.showValue;

    if (this.showing) {
      this.expandContentImmediately();
    }
  }

  toggle() {
    if (this.showing) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.showing = true;
    this.element.classList.add("t-collapsible-show");
    gsap.fromTo(
      this.contentTarget,
      {
        opacity: 0,
        maxHeight: 0,
      },
      {
        opacity: 1,
        maxHeight: this.contentTarget.scrollHeight,
        duration: 0.3,
        onComplete: () => {
          this.contentTarget.style.maxHeight = "none";
        },
      },
    );
  }

  hide() {
    this.showing = false;
    this.element.classList.remove("t-collapsible-show");
    this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + "px"; // Set maxHeight to current scrollHeight first

    gsap.fromTo(
      this.contentTarget,
      {
        opacity: 1,
        maxHeight: this.contentTarget.scrollHeight,
      },
      {
        opacity: 0,
        maxHeight: 0,
        duration: 0.3,
      },
    );
  }

  expandContentImmediately() {
    this.contentTarget.style.maxHeight = "none"; // Directly set to full height without animation
  }
}
