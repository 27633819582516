import { Controller } from "@hotwired/stimulus";
import { getIcon } from "material-file-icons";

export default class extends Controller {
  static classes = ["size"];

  connect() {
    super.connect();

    const iconSvg = getIcon(this.element.dataset.filename).svg;
    const domParser = new DOMParser();
    const domDocument = domParser.parseFromString(iconSvg, "text/html");
    const $icon = domDocument.querySelector("svg");
    $icon.style = "";
    $icon.classList.add(...this.sizeClasses);
    this.element.replaceWith($icon);
  }
}
