import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["indicator", "frame"];

  connect() {
    this.messageCreatedHandler = this.onMessageCreated.bind(this);
    this.messageReadHandler = this.onMessageRead.bind(this);

    this.element.addEventListener("message:created", this.messageCreatedHandler);
    this.element.addEventListener("message:read", this.messageReadHandler);
  }

  disconnect() {
    this.element.removeEventListener("message:created", this.messageCreatedHandler);
    this.element.removeEventListener("message:read", this.messageReadHandler);
  }

  onMessageCreated(e) {
    const { conversation, sender_id } = e.detail;

    if (this.currentUserId !== sender_id) {
      if (conversation.unread_count > 0) {
        this.showIndicator();
        this.reloadFrame();
      }
    }
  }

  onMessageRead(e) {
    const { total_unread, receiver_id } = e.detail;

    if (this.currentUserId === receiver_id) {
      if (total_unread > 0) {
        this.showIndicator();
      } else {
        this.hideIndicator();
      }

      this.reloadFrame();
    }
  }

  showIndicator() {
    this.indicatorTarget.classList.remove("hidden");
  }

  hideIndicator() {
    this.indicatorTarget.classList.add("hidden");
  }

  reloadFrame() {
    const src = this.frameTarget.getAttribute("src");
    this.frameTarget.src = "";
    this.frameTarget.src = src;
  }

  get currentUserId() {
    return document.querySelector("meta[name='current-user']").getAttribute("content");
  }
}
