import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "radio"];

  select(e) {
    this.itemTargets.forEach((item) => {
      item.classList.remove("selected");
    });

    e.currentTarget.classList.add("selected");
    const radio = e.currentTarget.querySelector("input[type='radio']");

    radio.checked = true;
  }
}
