import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";

export default class extends Controller {
  connect() {
    // Copy the initial content of the turbo-frame, so we can put it back in when frame becomes busy.

    const skeleton = this.element.querySelector(".loader");
    const loaderClone = skeleton.cloneNode(true);
    loaderClone.classList.remove("hidden");

    this.loaderHTML = loaderClone.innerHTML;

    console.log(this.loaderHTML);

    useMutation(this, { attributes: true });
  }

  mutate(entries) {
    entries.forEach((mutation) => {
      if (mutation.type === "attributes" && mutation.attributeName === "busy") {
        if (mutation.target.attributes.busy) {
          this.element.innerHTML = this.loaderHTML;
        }
      }
    });
  }

  reload() {
    const src = this.element.getAttribute("src");
    this.element.src = "";
    this.element.src = src;
  }
}
