import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.typeset();
  }

  typeset() {
    MathJax.typesetPromise();
  }
}
