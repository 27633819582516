import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["choice", "section"];

  select(e) {
    const selectedChoice = e.detail.value;

    this.sectionTargets.forEach((item) => {
      if (item.getAttribute("data-section") === selectedChoice) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
  }
}
