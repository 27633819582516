import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  loadVariables(e) {
    const amountId = this.element.getAttribute("data-amount-id");
    get(`/formulas/${e.detail.value}/variables?amount_id=${amountId}&target=${e.target.dataset.target}`, { responseKind: "turbo-stream" });
  }

  update(e) {
    if (e.detail.value !== "formula") {
      document.getElementById("formula-variables").innerHTML = "";
    }
  }
}
