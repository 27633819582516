import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["channelList", "directList", "archivedList", "listItem"];

  connect() {
    this.conversationCreated = this.onConversationCreated.bind(this);
    this.messageCreated = this.onMessageCreated.bind(this);
    this.messageRead = this.onMessageRead.bind(this);

    this.element.addEventListener("conversation:created", this.conversationCreated);
    this.element.addEventListener("message:created", this.messageCreated);
    this.element.addEventListener("message:read", this.messageRead);
  }

  disconnect() {
    this.element.removeEventListener("conversation:created", this.conversationCreated);
    this.element.removeEventListener("message:created", this.messageCreated);
    this.element.removeEventListener("message:read", this.messageRead);
  }

  select(e) {
    this.listItemTargets.forEach((item) => item.classList.remove("t-active"));
    e.currentTarget.classList.add("t-active");
  }

  onConversationCreated(e) {
    if (e.detail.receiver_id === this.currentUserId) {
      if (e.detail.type === "direct_chat") {
        this.directListTarget.insertAdjacentHTML("beforeend", e.detail.html);
        this.deactivateItem(this.channelListTarget);
      } else {
        this.channelListTarget.insertAdjacentHTML("beforeend", e.detail.html);
        this.deactivateItem(this.directListTarget);
      }
    } else {
      if (e.detail.type === "direct_chat") {
        this.deactivateItem(this.channelListTarget);
      } else {
        this.deactivateItem(this.directListTarget);
      }
    }
  }

  deactivateItem() {
    this.listItemTargets.forEach((item) => item.classList.remove("t-active"));
  }

  activateItem(item) {
    item.classList.add("t-active");
  }

  onMessageCreated(e) {
    if (e.detail.receiver_id === this.currentUserId) {
      this.updateUnreadCount(e);
    }
  }

  onMessageRead(e) {
    if (e.detail.receiver_id === this.currentUserId) {
      this.updateUnreadCount(e);
    }
  }

  updateUnreadCount(e) {
    const unreadCount = e.detail.conversation.unread_count;

    // update conversation list item unread count
    const unreadElement = document.getElementById(e.detail.conversation.unread_dom_id);

    if (unreadCount > 0) {
      unreadElement.classList.remove("hidden");
      unreadElement.textContent = unreadCount;
    } else {
      unreadElement.classList.add("hidden");
      unreadElement.textContent = unreadCount;
    }
  }

  get currentUserId() {
    return document.querySelector("meta[name='current-user']").getAttribute("content");
  }
}
