import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import { useHotkeys } from "stimulus-use/hotkeys";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["input", "results", "item", "groupHeader"];
  static values = { url: String };
  static debounces = ["search"];

  connect() {
    this.dialogController = this.application.getControllerForElementAndIdentifier(this.element, "dialog");
    useDebounce(this, { wait: 300 }); // 300ms debounce delay
    useHotkeys(this, {
      "ctrl+k": [this.openSearchPalette],
    });

    this.selectedIndex = -1;
    this.keyboardActive = false;
    this.mouseTimeout = null;

    // Attach dialog event listeners
    this.element.addEventListener("dialog:on-show", this.onDialogShow.bind(this));
    this.element.addEventListener("dialog:on-hide", this.onDialogHide.bind(this));
  }

  disconnect() {
    // Clean up event listeners when the controller is disconnected
    this.removeEventListeners();
    this.element.removeEventListener("dialog:on-show", this.onDialogShow);
    this.element.removeEventListener("dialog:on-hide", this.onDialogHide);
  }

  search() {
    const query = this.query;
    if (query) {
      this.performSearch(query);
    } else {
      this.resultsTarget.innerHTML = ""; // Clear results if input is empty
    }
  }

  performSearch(query) {
    post(this.urlValue, { body: { q: query }, responseKind: "turbo-stream" });
  }

  onDialogShow() {
    this.selectedIndex = -1;
    this.inputTarget.focus(); // Focus the input when the dialog is shown
    this.attachEventListeners(); // Attach keyboard event listeners
  }

  onDialogHide() {
    this.selectedIndex = -1;
    this.clearSearch(); // Clear the search when the dialog is hidden
    this.removeEventListeners(); // Remove keyboard event listeners
  }

  attachEventListeners() {
    this.inputTarget.addEventListener("keydown", this.onKeydown.bind(this));
    this.inputTarget.addEventListener("input", this.onInputChange.bind(this));
    this.resultsTarget.addEventListener("scroll", this.updateStickyHeader.bind(this));
  }

  removeEventListeners() {
    this.inputTarget.removeEventListener("keydown", this.onKeydown);
    this.inputTarget.removeEventListener("input", this.onInputChange);

    this.resultsTarget.addEventListener("scroll", this.updateStickyHeader);
  }

  onInputChange() {
    this.selectedIndex = -1;
    this.search();
  }

  onKeydown = (event) => {
    if (this.itemTargets.length > 0) {
      this.keyboardActive = true;

      clearTimeout(this.mouseTimeout);

      // Allow mouseover again after a short delay
      this.mouseTimeout = setTimeout(() => {
        this.keyboardActive = false;
      }, 500);

      const handler = this[`on${event.key}Keydown`];
      if (handler) handler(event);
    }
  };

  onArrowDownKeydown = (event) => {
    this.selectedIndex++;

    const selectedResult = this.itemTargets[this.selectedIndex];

    if (!selectedResult) {
      this.selectedIndex = 0;
    }

    this.highlightSelected();
    this.scrollIntoView();

    event.preventDefault();
  };

  onArrowUpKeydown = (event) => {
    this.selectedIndex--;

    const selectedResult = this.itemTargets[this.selectedIndex];

    if (!selectedResult) {
      this.selectedIndex = this.itemTargets.length - 1;
    }
    // const selectedResult = this.itemTargets[this.selectedIndex];
    this.highlightSelected();
    this.scrollIntoView();

    event.preventDefault();
  };

  onEnterKeydown = (event) => {
    if (this.selectedIndex > -1 && this.itemTargets.length > 0) {
      const selectedResult = this.itemTargets[this.selectedIndex];
      selectedResult.click();
      event.preventDefault();
    }
  };

  onMouseover = (event) => {
    if (this.keyboardActive) return; // Ignore mouse events when using keyboard

    this.selectedIndex = this.itemTargets.indexOf(event.currentTarget);

    this.highlightSelected();
  };

  highlightSelected() {
    // Remove highlight from all results
    this.itemTargets.forEach((result) => result.classList.remove("active"));

    // Highlight the selected result
    if (this.selectedIndex >= 0) {
      const selectedResult = this.itemTargets[this.selectedIndex];

      selectedResult.classList.add("active");
    }
  }

  scrollIntoView() {
    if (this.selectedIndex >= 0) {
      const selectedResult = this.itemTargets[this.selectedIndex];
      const resultTop = selectedResult.offsetTop;
      const resultsContainer = this.resultsTarget;

      if (this.selectedIndex === 0) {
        resultsContainer.scrollTop = resultTop - 50;
      } else {
        // Scroll into view
        selectedResult.scrollIntoView({
          block: "nearest", // Keeps scrolling minimal
          behavior: "smooth", // Optional: smooth scrolling
        });
      }
    }
  }

  updateStickyHeader() {
    const resultsContainer = this.resultsTarget;
    const scrollTop = resultsContainer.scrollTop;

    // Find the topmost visible result
    let topmostResult = null;
    this.itemTargets.forEach((result) => {
      const resultTop = result.offsetTop;
      const resultBottom = resultTop + result.offsetHeight;

      // Check if the result is within the visible area of the container
      if (resultTop <= scrollTop + resultsContainer.offsetHeight && resultBottom >= scrollTop) {
        if (!topmostResult || resultTop < topmostResult.offsetTop) {
          topmostResult = result;
        }
      }
    });

    // Remove sticky class from all group headers
    this.groupHeaderTargets.forEach((header) => header.classList.remove("sticky"));

    // Add sticky class to the header of the topmost visible result
    if (topmostResult) {
      const groupHeader = topmostResult.closest(".result-group").querySelector(".group-header");
      if (groupHeader) {
        groupHeader.classList.add("sticky");
      }
    }
  }

  followSelectedLink() {
    if (this.selectedIndex >= 0) {
      const selectedResult = this.itemTargets[this.selectedIndex];
      window.location.href = selectedResult.href;
    }
  }

  clearSearch() {
    this.query = "";
    this.selectedIndex = -1; // Reset the selected index
    this.resultsTarget.innerHTML = ""; // Clear results if input is empty
  }

  // Handle mouse clicks on search results
  selectResult(event) {
    this.selectedIndex = this.itemTargets.indexOf(event.currentTarget);
    this.highlightSelected();
  }

  openSearchPalette(e) {
    e.preventDefault();
    this.dialogController.show();
  }

  get query() {
    return this.inputTarget.value ? this.inputTarget.value.trim() : "";
  }

  set query(newValue) {
    this.inputTarget.value = newValue;
  }
}
