import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["darkIcon", "lightIcon"];

  toggle() {
    const currentTheme = document.documentElement.getAttribute("data-theme");
    const newTheme = currentTheme === "dark" ? "" : "dark";
    document.documentElement.setAttribute("data-theme", newTheme);
    this.darkIconTarget.classList.toggle("hidden");
    this.lightIconTarget.classList.toggle("hidden");
  }
}
