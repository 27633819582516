import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["root", "content", "header", "section", "link"];

  connect() {
    this.offset = this.headerTarget.offsetHeight + 18;
    this.addBottomPadding();
    this.sections = this.sectionTargets.map((section) => ({
      id: section.id,
      top: section.offsetTop,
      height: section.offsetHeight,
    }));

    this.updateActiveSection(); // Set initial active section
    this.rootTarget.addEventListener("scroll", this.updateActiveSection.bind(this));
    this.linkTargets.forEach((target) => target.addEventListener("click", this.handleLinkClick.bind(this)));
    window.addEventListener("resize", this.handleResize.bind(this));
    document.addEventListener("turbo:frame-render", this.handleResize.bind(this));
  }

  disconnect() {
    this.rootTarget.removeEventListener("scroll", this.updateActiveSection.bind(this));
    this.linkTargets.forEach((target) => target.removeEventListener("click", this.handleLinkClick.bind(this)));
    window.removeEventListener("resize", this.handleResize.bind(this));
    document.removeEventListener("turbo:frame-render", this.handleResize.bind(this));
  }

  handleResize() {
    this.offset = this.headerTarget.offsetHeight + 18;
    this.addBottomPadding(); // Recalculate padding on window resize
  }

  addBottomPadding() {
    const lastSection = this.sectionTargets[this.sectionTargets.length - 1];
    const rootViewportHeight = this.rootTarget.clientHeight;
    const lastSectionHeight = lastSection.offsetHeight;

    // Calculate the padding needed to ensure the last section can scroll to the top
    const paddingBottom = rootViewportHeight - lastSectionHeight;

    // Ensure that padding is only added if it's positive
    if (paddingBottom > 0) {
      this.contentTarget.style.paddingBottom = `${paddingBottom}px`;
    }
  }

  handleLinkClick(event) {
    event.preventDefault(); // Prevent the default anchor click behavior
    const targetId = event.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);

    // Get the top position of the target element relative to the root element
    const targetPosition = targetElement.getBoundingClientRect().top + this.rootTarget.scrollTop - this.rootTarget.getBoundingClientRect().top;

    // Calculate the final scroll position, including the offset
    const finalPosition = targetPosition - this.offset;

    // Scroll the root element to the calculated position
    this.rootTarget.scrollTo({
      top: finalPosition,
      // behavior: "smooth", // Smooth scrolling
    });

    // Delay the execution to allow the browser to complete the scroll
    setTimeout(() => {
      this.updateActiveSection();
    }, 300); // Adjust the delay time as needed based on smooth scroll duration
  }

  updateActiveSection() {
    const scrollPosition = this.rootTarget.scrollTop + this.offset;
    // const scrollPosition = this.rootTarget.scrollTop;
    let closestSection = this.sections[0];
    let closestDistance = Math.abs(scrollPosition - closestSection.top);

    this.sectionTargets.forEach((section) => {
      let sectionTop = section.getBoundingClientRect().top + this.rootTarget.scrollTop - this.rootTarget.getBoundingClientRect().top;
      let distance = Math.abs(scrollPosition - sectionTop);

      // Update the closest section if the current one is closer
      if (distance < closestDistance) {
        closestDistance = distance;
        closestSection = section;
      }
    });

    this.clearActive();

    const targetLink = this.linkTargets.find((target) => target.getAttribute("href") === `#${closestSection.id}`);

    if (targetLink) {
      targetLink.classList.add("t-scrollspy-active");
    }
  }

  clearActive() {
    this.linkTargets.forEach((target) => target.classList.remove("t-scrollspy-active"));
  }
}
