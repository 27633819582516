import { Controller } from "@hotwired/stimulus";
import data from "@emoji-mart/data";
import { Picker } from "emoji-mart";
import { createPopper } from "@popperjs/core";
import prettyBytes from "pretty-bytes";

export default class extends Controller {
  static targets = ["trixEditor", "fileContainer", "emojiButton", "emojiPicker"];

  connect() {
    this._bindEventListeners();
    this._initEmojiPicker();
  }

  async toggleEmoji(e) {
    e.preventDefault();
    e.stopPropagation();
    this.emojiPickerTarget.classList.toggle("hidden");
    await this.emojiPopper.update();
  }

  _initEmojiPicker() {
    const picker = new Picker({
      data,
      theme: "light",
      categories: ["people", "nature", "foods", "activity", "places", "objects", "symbols", "flags"],
      onEmojiSelect: this._bindEmojiEventListener.bind(this),
    });

    this.emojiPickerTarget.appendChild(picker);
    this.emojiPopper = createPopper(this.emojiButtonTarget, this.emojiPickerTarget, { placement: "top" });

    document.addEventListener(
      "click",
      function (e) {
        const $targetEl = e.target;
        const $emojiPicker = $targetEl.closest(".emojiPicker");
        const $emojiButton = $targetEl.closest(".emojiButton");
        const clickedOnEmojiButton = $targetEl.classList.contains(".emojiButton") || $emojiButton;

        if (clickedOnEmojiButton) {
          return;
        }

        if (!$emojiPicker) {
          // close picker after clicked
          this.emojiPickerTarget.classList.add("hidden");
        }
      }.bind(this),
    );
  }

  _bindEmojiEventListener(event) {
    this.trixEditorTarget.editor.insertString(event.native);
    // this.emojiPickerTarget.classList.add("hidden");
  }

  // custom file upload
  _bindEventListeners() {
    this.trixEditorTarget.addEventListener("trix-initialize", this._handleInitialize.bind(this));
    this.trixEditorTarget.addEventListener("trix-file-accept", this._handleFileAccept.bind(this));
    this.trixEditorTarget.addEventListener("trix-focus", this._handleFocus.bind(this));
    this.trixEditorTarget.addEventListener("trix-blur", this._handleBlur.bind(this));
    // this.trixEditorTarget.addEventListener("trix-attachment-add", this._handleAttachmentAdd.bind(this));
  }

  // _handleAttachmentAdd(event) {
  //   console.log("_handleAttachmentAdd");
  //   console.log(event);
  // }

  _handleInitialize(event) {
    const form = this.element.closest("form");

    this.trixEditorTarget.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && !e.ctrlKey) {
        e.preventDefault();
        if (form) {
          form.requestSubmit();
        }
      } else if (e.key === "Enter" && e.ctrlKey) {
        this.trixEditorTarget.editor.insertLineBreak();
        e.preventDefault();
      }
    });
  }

  _handleFocus(event) {
    this.element.classList.add("trix-focused");
  }

  _handleBlur(event) {
    this.element.classList.remove("trix-focused");
  }

  _handleFileAccept(event) {
    event.preventDefault();

    const attachment = event.file;
    const domParser = new DOMParser();
    const templateHTML = this._fileTemplate(attachment);
    const domDocument = domParser.parseFromString(templateHTML, "text/html");
    const $attachmentEl = domDocument.querySelector(".attachment");

    this.fileContainerTarget.appendChild($attachmentEl);

    this._uploadAttachment($attachmentEl, attachment);
  }

  _setUploadProgress($attachmentEl, progress) {
    const $progressBar = $attachmentEl.querySelector(".progressbar");
    $progressBar.setAttribute("aria-valuenow", progress);
    $progressBar.style.width = `${progress}%`;
  }

  _uploadAttachment($attachmentEl, file) {
    const uploadUrl = this.trixEditorTarget.dataset.uploadUrl;
    const documentInput = this.trixEditorTarget.dataset.documentInput;
    const csrfToken = document.querySelector("[name='csrf-token']").content;

    let form = new FormData();
    form.append("Content-Type", file.type);
    form.append("document[name]", file.name);
    form.append("document[file]", file);
    form.append("document_input", documentInput);

    let xhr = new XMLHttpRequest();
    xhr.open("POST", uploadUrl, true);
    xhr.setRequestHeader("X-CSRF-Token", csrfToken);

    xhr.upload.onprogress = (event) => {
      let progress = (event.loaded / event.total) * 100;
      this._setUploadProgress($attachmentEl, progress);
    };

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        this._updateAttachmentFromServer($attachmentEl, xhr.response);
        // let data = JSON.parse(xhr.responseText);
        // console.log(data);
      } else {
        console.log("error");
      }
    };

    xhr.send(form);
  }

  _updateAttachmentFromServer($attachmentEl, str) {
    const domParser = new DOMParser();
    const domDocument = domParser.parseFromString(str, "text/html");
    const $newAttachmentEl = domDocument.querySelector(".attachment");

    $attachmentEl.replaceWith($newAttachmentEl);
  }

  _fileIsImage(file) {
    const fileType = file.type;
    return fileType === "image/jpeg" || fileType === "image/png" || fileType === "image/gif";
  }

  _fileTemplate(file) {
    return `
      <div class="inline-flex flex-col gap-0.5 attachment">
        <div class="inline-flex gap-1 items-center py-1 px-2 rounded-lg border file text-gray-400">
          <span data-controller="fileicon" data-filename="${file.name}" data-fileicon-size-class="w-8 h-8"></span>
          <div class="flex-1 pr-1 space-y-0.5">
            <div class="text-sm font-medium">${file.name}</div>
            <div class="text-xs text-gray-500">${prettyBytes(file.size, { maximumFractionDigits: 1 })}</div>
          </div>
        </div>
        <div class="flex overflow-hidden w-full h-1.5 bg-gray-200 rounded-full dark:bg-gray-700">
          <div class="flex overflow-hidden flex-col justify-center bg-blue-500 progressbar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    `;
  }
}
