document.addEventListener("turbo:before-fetch-request", function (event) {
  const dialog = event.target.closest(".t-dialog");
  const { headers } = event.detail.fetchOptions;

  if (dialog) {
    headers["X-Dialog"] = dialog.id;
  }
});

// Prevent data value attributes from being changed
// document.addEventListener("turbo:before-morph-attribute", (event) => {
//   const { attributeName } = event.detail;

//   const openStateRegex = /^data-([a-zA-Z0-9_-]+)-opened(?:-([a-zA-Z0-9_-]+))?-value$/;

//   if (attributeName.match(openStateRegex)) {
//     event.preventDefault();
//   }

//   if (attributeName === "class") {
//     if (event.target.classList.contains("t-collapsible-open") || event.target.classList.contains("t-collapsible-openall")) {
//       event.preventDefault();
//     }
//   }

//   if (attributeName === "style") {
//     if (event.target.classList.contains("t-collapsible-content")) {
//       event.preventDefault();
//     }
//   }
// });
