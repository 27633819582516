import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { put, FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static targets = ["group", "columnItemlist", "groupHeader", "groupItemlist"];

  connect() {
    this.initializeSortableColumns();
    this.initializeSortableGroups();
    this.initializeSortableItems();
    this.initializeGroupHeaderDrop();
  }

  initializeSortableColumns() {
    Sortable.create(this.element, {
      group: "column",
      animation: 150,
      direction: "horizontal",
      handle: "[data-column-draghandle]",
      draggable: ".board-column",
      onEnd: this.handleColumnDrop.bind(this),
    });
  }

  initializeSortableGroups() {
    this.columnItemlistTargets.forEach((columnItemlist) => {
      Sortable.create(columnItemlist, {
        group: "group",
        animation: 150,
        handle: "[data-group-draghandle]",
        draggable: ".board-group",
        onEnd: this.handleGroupDrop.bind(this),
      });
    });
  }

  initializeSortableItems() {
    this.groupItemlistTargets.forEach((list) => {
      Sortable.create(list, {
        group: "item",
        animation: 150,
        handle: "[data-item-draghandle]",
        draggable: ".board-item",
        dragClass: "item-sortable-drag",
        onEnd: this.handleItemDrop.bind(this),
        onMove: this.handleItemMove.bind(this),
      });
    });
  }

  initializeGroupHeaderDrop() {
    this.groupHeaderTargets.forEach((header) => {
      Sortable.create(header, {
        group: {
          name: "item",
          pull: false,
          put: function (toSortable, fromSortable, draggedItem) {
            return draggedItem.classList.contains("board-item");
          },
        },
        sort: false, // Prevent sorting within headers
        onAdd: (event) => {
          const item = event.item;
          const group = event.to.closest(".board-group");
          group.querySelector(".board-group-itemlist").appendChild(item); // Append item to the associated list
        },
      });
    });
  }

  handleItemMove(event) {
    const item = event.item;
    const newParentList = event.to;
    const draggedItem = event.dragged;

    this.clearHeadersHighlight();

    if (newParentList.classList.contains("board-group-header")) {
      draggedItem.classList.add("hidden");

      newParentList.closest(".board-group").classList.add("!bg-primary-200");
    } else {
      draggedItem.classList.remove("hidden");
    }
  }

  clearHeadersHighlight() {
    this.groupTargets.forEach((header) => {
      header.classList.remove("!bg-primary-200");
    });
  }

  handleColumnDrop(event) {
    // sync server position
    const moveUrl = event.item.dataset.moveUrl;
    if (moveUrl) {
      const params = {
        query: {
          position: event.newIndex + 1,
        },
      };
      put(moveUrl, params);
    }
  }

  handleItemDrop(event) {
    const item = event.item;
    const newParentList = event.to;
    const oldParentList = event.from;

    item.classList.remove("item-sortable-drag");

    this.clearHeadersHighlight();
    item.classList.remove("hidden");

    // Append the item to the new list if dropped to group header
    if (newParentList.classList.contains("board-group-header")) {
      newParentList.closest(".board-group").querySelector(".board-group-itemlist").appendChild(item);
    }

    const position = newParentList.classList.contains("board-group-header") ? 1 : event.newIndex + 1;
    this.updateCountersOnMove(item, oldParentList, newParentList, position);
  }

  handleGroupDrop(event) {
    const item = event.item;
    const newParentList = event.to;
    const oldParentList = event.from;

    // Update count
    const newColumn = newParentList.closest(".board-column");
    const oldColumn = oldParentList.closest(".board-column");

    const newColumnItemCount = newColumn.querySelectorAll(".board-item").length;
    const oldColumnItemCount = oldColumn.querySelectorAll(".board-item").length;

    newColumn.querySelector(".board-column-counter").textContent = newColumnItemCount;
    oldColumn.querySelector(".board-column-counter").textContent = oldColumnItemCount;

    // sync server position
    const moveUrl = event.item.dataset.moveUrl;

    if (moveUrl) {
      const params = {
        query: {
          from: oldColumn.dataset.id,
          to: newColumn.dataset.id,
          position: event.newIndex + 1,
        },
      };
      put(moveUrl, params);
    }
  }

  moveToNextStatus(e) {
    const item = e.target.closest(".board-item");
    const oldParentList = item.closest(".board-group");
    const newParentList = oldParentList.nextElementSibling;

    newParentList.querySelector(".board-group-itemlist").appendChild(item);

    this.updateCountersOnMove(item, oldParentList, newParentList, null);
  }

  moveToPreviousStatus(e) {
    const item = e.target.closest(".board-item");
    const oldParentList = item.closest(".board-group");
    const newParentList = oldParentList.previousElementSibling;

    newParentList.querySelector(".board-group-itemlist").appendChild(item);

    this.updateCountersOnMove(item, oldParentList, newParentList, null);
  }

  moveToPreviousStage(e) {
    const item = e.target.closest(".board-item");
    const oldParentList = item.closest(".board-group");
    const newParentList = item.closest(".board-column").previousElementSibling.querySelector(".board-group");

    newParentList.querySelector(".board-group-itemlist").appendChild(item);

    this.updateCountersOnMove(item, oldParentList, newParentList, null);
  }

  moveToNextStage(e) {
    const item = e.target.closest(".board-item");
    const oldParentList = item.closest(".board-group");
    const newParentList = item.closest(".board-column").nextElementSibling.querySelector(".board-group");

    newParentList.querySelector(".board-group-itemlist").appendChild(item);

    this.updateCountersOnMove(item, oldParentList, newParentList, null);
  }

  async removeItem(e) {
    const item = e.target.closest(".board-item");
    const parentGroup = item.closest(".board-group");
    const parentColumn = item.closest(".board-column");

    const { method, href, confirm } = e.currentTarget.dataset;

    if (window.confirm(confirm)) {
      const request = new FetchRequest(method, href, {
        responseKind: "turbo-stream",
      });
      const response = await request.perform();
      if (!response.ok) {
        window.alert("Error occurred!");
      }

      item.remove();

      this.updateCountersOnRemove(item, parentColumn, parentGroup);
    }
  }

  updateCountersOnRemove(item, parentColumn, parentGroup) {
    const newColumnItemCount = parentColumn.querySelectorAll(".board-item").length;
    const newGroupItemCount = parentGroup.querySelectorAll(".board-item").length;

    this.markHasItemToElement(parentColumn, newColumnItemCount, "b-column-has-items");
    this.markHasItemToElement(parentGroup, newGroupItemCount, "b-group-has-items");

    parentColumn.querySelector(".board-column-counter").textContent = newColumnItemCount;
    parentGroup.querySelector(".board-group-counter").textContent = newGroupItemCount;
  }

  markHasItemToElement(element, count, className) {
    if (count > 0) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }

  updateCountersOnMove(item, oldParentList, newParentList, position) {
    // Update count
    const newColumn = newParentList.closest(".board-column");
    const oldColumn = oldParentList.closest(".board-column");

    const newGroup = newParentList.closest(".board-group");
    const oldGroup = oldParentList.closest(".board-group");

    const newColumnItemCount = newColumn.querySelectorAll(".board-item").length;
    const oldColumnItemCount = oldColumn.querySelectorAll(".board-item").length;

    const newGroupItemCount = newGroup.querySelectorAll(".board-item").length;
    const oldGroupItemCount = oldGroup.querySelectorAll(".board-item").length;

    this.markHasItemToElement(newColumn, newColumnItemCount, "b-column-has-items");
    this.markHasItemToElement(newGroup, newGroupItemCount, "b-group-has-items");
    this.markHasItemToElement(oldColumn, oldColumnItemCount, "b-column-has-items");
    this.markHasItemToElement(oldGroup, oldGroupItemCount, "b-group-has-items");

    newColumn.querySelector(".board-column-counter").textContent = newColumnItemCount;
    oldColumn.querySelector(".board-column-counter").textContent = oldColumnItemCount;

    newGroup.querySelector(".board-group-counter").textContent = newGroupItemCount;
    oldGroup.querySelector(".board-group-counter").textContent = oldGroupItemCount;

    // sync server position
    const moveUrl = item.dataset.moveUrl;
    const newPosition = position ? position : newGroupItemCount;

    if (moveUrl) {
      const params = {
        query: {
          from: oldGroup.dataset.id,
          to: newGroup.dataset.id,
          position: newPosition,
        },
        responseKind: "turbo-stream",
      };

      put(moveUrl, params);
    }
  }
}
