import { Controller } from "@hotwired/stimulus";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import DragDrop from "@uppy/drag-drop";
import Dashboard from "@uppy/dashboard";

export default class extends Controller {
  static values = {
    url: String,
    preview: String,
  };

  connect() {
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    this.uppy = new Uppy({
      restrictions: {
        maxFileSize: this.megabyteToByte(5),
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*"],
      },
    })
      .use(Dashboard, {
        inline: true,
        target: this.element,
        replaceTargetContent: true,
        showProgressDetails: true,
        note: "Images and video only",
        width: "100%",
        height: 320,
        browserBackButtonClose: true,
        proudlyDisplayPoweredByUppy: false,
        locale: {
          strings: {
            dropPasteFiles: "Drop cover image here or %{browseFiles}",
            browseFiles: "browse file",
          },
        },
        doneButtonHandler: this.onDoneButtonClick.bind(this),
      })
      .use(XHRUpload, {
        endpoint: this.urlValue,
        fieldName: "file",
        formData: true,
        allowedMetaFields: ["authenticity_token"],
      });

    this.uppy.on("upload-success", (file, response) => {
      file.uploadURL = response.body.uploadURL;

      const img = this.preview.querySelector("img");
      img.src = file.uploadURL;
    });

    this.uppy.on("error", (error) => {
      alert("Failed to upload the image! Please contact support team.");
    });

    this.uppy.on("file-added", (file) => {
      this.uppy.setFileMeta(file.id, {
        authenticity_token: csrfToken,
      });
    });
  }

  disconnect() {
    this.uppy.close();
  }

  get preview() {
    return document.getElementById(this.previewValue);
  }

  onDoneButtonClick() {
    this.element.remove();
    this.preview.classList.remove("hidden");
  }

  megabyteToByte(size) {
    return size * 1024 * 1024;
  }
}
