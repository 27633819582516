import { Controller } from "@hotwired/stimulus";
import { useWindowResize } from "stimulus-use";

export default class extends Controller {
  static targets = ["itemlist", "item", "panel"];

  connect() {
    useWindowResize(this);

    this.itemlistTarget.addEventListener("click", (e) => {
      const clickedTab = e.target.closest(".t-tab-item");

      if (!clickedTab || clickedTab === this.currentTab) return;

      this.switchTab(clickedTab);
    });

    this.setInitialActiveTab();
  }

  setInitialActiveTab() {
    const currentTabWidth = this.currentTab.offsetWidth / this.itemlistTarget.offsetWidth;
    this.itemlistTarget.style.setProperty("--_width", currentTabWidth);
    this.itemlistTarget.style.setProperty("--_left", this.currentTab.offsetLeft + "px");
  }

  windowResize({ width, height, event }) {
    this.setInitialActiveTab();
  }

  switchTab(newTab) {
    const activePanelId = newTab.getAttribute("aria-controls");
    const activePanel = this.element.querySelector("#" + CSS.escape(activePanelId));

    this.itemTargets.forEach((item) => {
      item.setAttribute("aria-selected", false);
      item.setAttribute("tabindex", "-1");
    });

    this.panelTargets.forEach((panel) => {
      panel.setAttribute("hidden", true);
    });

    activePanel.removeAttribute("hidden", false);

    newTab.setAttribute("aria-selected", true);
    newTab.setAttribute("tabindex", "0");
    newTab.focus();
    this.moveIndicator(newTab);

    // Update URL without page reload
    const url = new URL(window.location);
    url.searchParams.set("tab", newTab.getAttribute("aria-controls"));
    window.history.pushState({}, "", url);
  }

  moveIndicator(newTab) {
    const newTabWidth = newTab.offsetWidth / this.itemlistTarget.offsetWidth;

    this.itemlistTarget.style.setProperty("--_left", newTab.offsetLeft + "px");
    this.itemlistTarget.style.setProperty("--_width", newTabWidth);
  }

  get currentTab() {
    return this.itemlistTarget.querySelector('[aria-selected="true"]');
  }

  // activateTabFromParams() {
  //   const params = new URLSearchParams(window.location.search);
  //   const tabId = params.get("tab");
  //
  //   if (tabId) {
  //     this.activateTab(tabId);
  //   } else {
  //     // Activate first tab by default if no param
  //     const firstTabId = this.tabTargets[0].dataset.tabId;
  //     this.activateTab(firstTabId);
  //   }
  // }
}
