import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:before-frame-render", this.onBeforeFrameRender.bind(this));
    this.element.addEventListener("turbo:frame-render", this.onFrameRender.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("turbo:before-frame-render", this.onBeforeFrameRender.bind(this));
    this.element.removeEventListener("turbo:frame-render", this.onFrameRender.bind(this));
  }

  onBeforeFrameRender(event) {
    const currentFrame = event.target;
    const originalRender = event.detail.render;

    // event.detail.render = async (a, b) => {
    //   console.log("old", a.innerHTML);
    //   console.log("new", b.innerHTML);
    //   await originalRender(a, b);
    //
    //   console.log(b);
    //   gsap.fromTo(b, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: "power2.out" });
    // };

    event.preventDefault();

    gsap.to(currentFrame, {
      opacity: 0,
      duration: 0.3,
      ease: "power2.in",
      onComplete: () => {
        event.detail.resume();
      },
    });

    // gsap.to(event.target, { opacity: 0, y: -20, duration: 0.3, ease: "power2.in" });
  }

  onFrameRender(event) {
    const frame = event.target;
    gsap.fromTo(
      frame,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power2.out",
        onComplete: () => {
          frame.focus();
        },
      },
    );
  }
}
