import { Controller } from "@hotwired/stimulus";
import { application } from "../application";

export default class extends Controller {
  static targets = ["collapsible"];
  static values = { openedAll: false };

  toggle() {
    if (this.openedAllValue) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.element.classList.add("t-collapsible-showall");
    this.collapsibleTargets.forEach((c) => {
      const collapsible = application.getControllerForElementAndIdentifier(c, "collapsible");
      collapsible.show();
    });
    this.openedAllValue = true;
  }

  close() {
    this.element.classList.remove("t-collapsible-showall");
    this.collapsibleTargets.forEach((c) => {
      const collapsible = application.getControllerForElementAndIdentifier(c, "collapsible");
      collapsible.hide();
    });
    this.openedAllValue = false;
  }
}
