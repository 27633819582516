import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  select(e) {
    this.itemTargets.forEach((item) => item.classList.remove(...this.activeClasses));
    e.currentTarget.classList.add(...this.activeClasses);
  }

  get activeClasses() {
    return (this.element.dataset.activeClass || "active").split(" ");
  }
}
