import BaseController from "./base_controller.js";
import HSTooltip from "flyonui/dist/js/tooltip.js";

export default class extends BaseController {
  connect() {
    super.connect();
    this.init();
  }

  init() {
    if (!window.$hsTooltipCollection) window.$hsTooltipCollection = [];

    this.hsTooltip = new HSTooltip(this.element);
  }

  prefix() {
    return "tooltip";
  }

  collection() {
    if (!window.$hsTooltipCollection) window.$hsTooltipCollection = [];

    return window.$hsTooltipCollection;
  }
}
