import { StreamActions } from "@hotwired/turbo";

StreamActions.close_dialog = function () {
  const targetElement = document.getElementById(this.target);

  targetElement.dispatchEvent(
    new CustomEvent("dialog:hide", {
      bubbles: true,
      cancelable: true,
      detail: {},
    }),
  );
};
