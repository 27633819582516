import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";

export default class extends Controller {
  connect() {
    this.beforeFrameRender = this.onBeforeFrameRender.bind(this);

    this.currentHeight = this.element.offsetHeight;

    this.element.addEventListener("turbo:before-frame-render", this.beforeFrameRender);
  }

  disconnect() {
    this.element.removeEventListener("turbo:before-frame-render", this.beforeFrameRender);
  }

  onBeforeFrameRender(event) {
    event.preventDefault();

    // ensure show frame after gsap finished it transition, delay timing should match animation of trigger (i.e dialog)
    setTimeout(() => {
      event.detail.resume();
    }, 300);

    const originalRender = event.detail.render;

    // Custom render to apply animation
    event.detail.render = async (currentFrame, newFrame) => {
      const loadingIndicator = currentFrame.querySelector(".t-loading");

      if (loadingIndicator) {
        // Use GSAP to fade out the loading indicator
        await gsap.to(loadingIndicator, { opacity: 0, duration: 0.2 });

        // Remove the loading indicator after fade-out completes
        // loadingIndicator.remove();
      }

      await originalRender(currentFrame, newFrame);

      const afterHeight = currentFrame.offsetHeight;

      gsap.set(currentFrame, { overflow: "hidden" });

      const timeline = gsap.timeline({
        onComplete: () => {
          gsap.set(currentFrame, { overflow: "", height: "auto" });
        },
      });
      timeline.fromTo(currentFrame, { height: this.currentHeight }, { height: afterHeight, duration: 0.3 });
    };
  }
}
