import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";

export default class extends Controller {
  connect() {
    this.beforeFrameRenderHandler = this.onBeforeFrameRender.bind(this);
    this.frameRenderHandler = this.onFrameRender.bind(this);

    this.element.addEventListener("turbo:before-frame-render", this.beforeFrameRenderHandler);
    this.element.addEventListener("turbo:frame-render", this.frameRenderHandler);
  }

  disconnect() {
    this.element.removeEventListener("turbo:before-frame-render", this.beforeFrameRenderHandler);
    this.element.removeEventListener("turbo:frame-render", this.frameRenderHandler);
  }

  onBeforeFrameRender(event) {
    const currentFrame = event.target;
    event.preventDefault();

    gsap.to(currentFrame.firstElementChild, {
      opacity: 0,
      duration: 0.3,
    });

    setTimeout(() => {
      event.detail.resume(); // Proceed with rendering the new content after animation
    }, 300);
  }

  onFrameRender(event) {
    const frame = event.target;

    gsap.to(frame.firstElementChild, {
      opacity: 1,
      duration: 0.3,
    });
  }
}
