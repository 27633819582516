import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["messageList", "jumpButton", "newMessageAlert", "loadOlder"];

  connect() {
    this.messageCreatedHandler = this.onMessageCreated.bind(this);
    this.olderMessageLoadedHandler = this.onOlderMessageLoaded.bind(this);
    this.olderMessageBeforeLoadHandler = this.onOlderMessageBeforeLoad.bind(this);

    this.element.addEventListener("message:created", this.messageCreatedHandler);

    if (this.hasLoadOlderTarget) {
      this.loadOlderTarget.addEventListener("turbo:before-frame-render", this.olderMessageBeforeLoadHandler);
      this.loadOlderTarget.addEventListener("turbo:frame-render", this.olderMessageLoadedHandler);
    }

    this.scrollToBottom(false);
  }

  disconnect() {
    // this.messageListTarget.removeEventListener("message:created", this.messageCreatedHandler);
    this.element.removeEventListener("message:created", this.messageCreatedHandler);

    if (this.hasLoadOlderTarget) {
      this.loadOlderTarget.removeEventListener("turbo:before-frame-render", this.olderMessageBeforeLoadHandler);
      this.loadOlderTarget.removeEventListener("turbo:frame-render", this.olderMessageLoadedHandler);
    }
  }

  jumpToPresent(event) {
    // const latestMessage = this.messageListTarget.lastElementChild;
    // latestMessage.scrollIntoView({ behavior: "smooth" });
    this.scrollToBottom(true);

    this.newMessageAlertTarget.classList.add("hidden");
  }

  async onOlderMessageLoaded(e) {
    const turboFrame = e.target;
    const content = turboFrame.querySelector(".older-messages");

    if (content) {
      turboFrame.removeChild(content);
      turboFrame.insertAdjacentHTML("afterend", content.innerHTML);

      await new Promise((resolve) => setTimeout(() => resolve(), 0)); // trick to ensure message is appended
      this.restoreScrollPosition();
    }
  }

  onOlderMessageBeforeLoad(e) {
    this.previousScrollHeight = this.messageListTarget.scrollHeight;
    this.previousScrollTop = this.messageListTarget.scrollTop;
  }

  restoreScrollPosition() {
    this.messageListTarget.scrollTop = this.messageListTarget.scrollHeight - this.previousScrollHeight + this.previousScrollTop;
  }

  async onMessageCreated(e) {
    if (this.currentUserId !== e.detail.sender_id) {
      this.messageListTarget.insertAdjacentHTML("beforeend", e.detail.html);

      await new Promise((resolve) => setTimeout(() => resolve(), 0)); // trick to ensure message is appended

      this.alertNewMessageIfFarFromBottom();
    }
  }

  alertNewMessageIfFarFromBottom() {
    const { scrollTop, scrollHeight, clientHeight } = this.messageListTarget;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

    if (distanceFromBottom < clientHeight / 2) {
      this.scrollToBottom();
    } else {
      // on new messages, if current scroll is too far from bottom, show the alert indicator
      this.newMessageAlertTarget.classList.remove("hidden");
    }
  }

  onScroll(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    const distanceFromBottom = Math.abs(scrollHeight - scrollTop - clientHeight);

    // const lastMessageHeight = this.lastMessage.offsetHeight;
    // const viewportHeight = Math.abs(this.element.offsetHeight - 200);
    const isFarFromBottom = distanceFromBottom < clientHeight - 200;
    const isNearBottom = distanceFromBottom < 100;

    if (isFarFromBottom) {
      this.jumpButtonTarget.classList.add("hidden");
    } else {
      this.jumpButtonTarget.classList.remove("hidden");
    }

    if (isNearBottom) {
      this.newMessageAlertTarget.classList.add("hidden");
    }
  }

  scrollToBottom(smooth) {
    if (!smooth) {
      // Temporarily override the smooth behavior
      this.messageListTarget.style.scrollBehavior = "auto";
    }

    this.messageListTarget.scrollTo({
      top: this.messageListTarget.scrollHeight,
      behavior: smooth ? "smooth" : "auto",
    });

    if (!smooth) {
      // Restore the original CSS behavior in the next frame
      requestAnimationFrame(() => {
        this.messageListTarget.style.scrollBehavior = "";
      });
    }
  }

  get root() {
    return document.getElementById("root");
  }

  get currentUserId() {
    return document.querySelector("meta[name='current-user']").getAttribute("content");
  }
}
