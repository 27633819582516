import { Controller } from "@hotwired/stimulus";
import { useMatchMedia } from "stimulus-use";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["header", "offcanvas", "content"];

  connect() {
    this.shouldDocked = true;
    this.offcanvasWidth = "512px";
    this.showing = false;

    this.adjustOffcanvasHeight();
    window.addEventListener("resize", this.adjustOffcanvasHeight.bind(this));

    useMatchMedia(this, {
      mediaQueries: {
        sm: "(min-width: 1280px)",
      },
    });
  }

  disconnect() {
    window.removeEventListener("resize", this.adjustOffcanvasHeight.bind(this));
  }

  isSm({ name, media, matches, event }) {
    this.shouldDocked = true;
  }

  notSm({ name, media, matches, event }) {
    this.shouldDocked = false;
  }

  show(e) {
    if (this.showing) {
      return;
    }

    this.showing = true;

    const timeline = gsap.timeline();

    timeline.to(this.offcanvasTarget, {
      opacity: 1,
      x: "0%",
      width: this.offcanvasWidth,
      duration: 0.3,
    });

    if (this.shouldDocked) {
      timeline.to(
        this.contentTarget,
        {
          marginRight: this.offcanvasWidth,
          duration: 0.3,
        },
        "<",
      );
    }
  }
  hide(e) {
    if (!this.showing) {
      return;
    }

    this.showing = false;

    const timeline = gsap.timeline();

    timeline.to(this.offcanvasTarget, {
      opacity: 0,
      x: "100%",
      duration: 0.3,
    });

    if (this.shouldDocked) {
      timeline.to(
        this.contentTarget,
        {
          marginRight: 0,
          duration: 0.3,
        },
        "<",
      );
    }
  }

  adjustOffcanvasHeight() {
    const headerHeight = this.headerTarget.offsetHeight;
    this.offcanvasTarget.style.top = `${headerHeight}px`;
  }
}
