import UploadController from "./upload_controller";

export default class extends UploadController {
  connect() {
    super.connect();
  }

  _getFileTypes() {
    return ["image/*"];
  }
}
