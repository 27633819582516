import { Controller } from "@hotwired/stimulus";
import { autoUpdate, computePosition, offset, flip, shift } from "@floating-ui/dom";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["menu"];
  static values = { show: Boolean };

  connect() {
    this.showing = this.showValue;
    this.anchor = document.getElementById(this.element.dataset.anchor);
    this.menu = this.menuTarget;
    this.placement = this.element.dataset.placement || "bottom-end";
    this.portal = document.getElementById(this.element.dataset.portal) || document.body;
    this.offsetValue = this.element.dataset.offset || 8;
    this.originalParent = this.menuTarget.parentNode;

    if (!this.anchor) {
      throw new Error(`Dropdown anchor not found! Please ensure the id correctly passed.`);
    }

    this.showDropdownHandler = this.show.bind(this);
    this.hideDropdownHandler = this.hide.bind(this);
    this.toggleDropdownHandler = this.toggle.bind(this);

    this.element.addEventListener("dropdown:show", this.showDropdownHandler);
    this.element.addEventListener("dropdown:hide", this.hideDropdownHandler);
    this.element.addEventListener("dropdown:toggle", this.toggleDropdownHandler);

    if (this.showing) this.show();
  }

  disconnect() {
    this.element.removeEventListener("dropdown:show", this.showDropdownHandler);
    this.element.removeEventListener("dropdown:hide", this.hideDropdownHandler);
    this.element.removeEventListener("dropdown:toggle", this.toggleDropdownHandler);
  }

  show() {
    if (this.showing) return;
    this.showing = true;

    this.anchor.classList.add("t-dropdown-show");

    // Teleport to portal, ensure the menu always appear on top of other elements
    this.portal.appendChild(this.menu);
    this.menu.style.display = "block";
    this.bindOutsideClick();
    this.updatePosition();

    gsap.fromTo(
      this.menu,
      {
        opacity: 0,
        y: -8,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
      },
    );
  }

  hide() {
    if (!this.showing) return;
    this.showing = false;

    this.anchor.classList.remove("t-dropdown-show");

    gsap.fromTo(
      this.menu,
      {
        opacity: 1,
        y: 0,
      },
      {
        opacity: 0,
        y: -8,
        duration: 0.3,
        onComplete: () => {
          this.menu.style.display = "";

          // Revert menu back to original parent
          this.originalParent.appendChild(this.menu);

          this.cleanOutsideClick();
        },
      },
    );
  }

  toggle() {
    if (this.showing) {
      this.hide();
    } else {
      this.show();
    }
  }

  updatePosition() {
    computePosition(this.anchor, this.menu, {
      placement: this.placement,
      // strategy: "fixed",
      middleware: [offset(this.offsetValue), flip(), shift()],
    }).then(({ x, y }) => {
      Object.assign(this.menu.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  bindOutsideClick() {
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  cleanOutsideClick() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  handleClickOutside(event) {
    if (this.showing) {
      if (!this.anchor.contains(event.target) && !this.menu.contains(event.target)) {
        // hide the dropdown if the click is outside the dropdown
        this.hide();
      } else if (event.target.closest(".t-menu-item")) {
        // hide the dropdown if a dropdown item is clicked
        setTimeout(() => {
          this.hide();
        }, 300);
      }
    }
  }
}
