import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe";
import Handlebars from "handlebars";

export default class extends Controller {
  static targets = ["slider", "image", "prevButton", "nextButton"];

  connect() {
    this.initSwiper();
    this.initGallery();

    this.uploadCompleteHandler = this.onUploadComplete.bind(this);
    this.element.addEventListener("photo-upload:complete", this.uploadCompleteHandler);

    // this.uploadSuccessHandler = this.onUploadSuccess.bind(this);
    // this.element.addEventListener("photo-upload:success", this.uploadSuccessHandler);
  }

  disconnect() {
    this.lightbox.destroy();
    this.swiper.destroy();

    this.element.removeEventListener("photo-upload:complete", this.uploadCompleteHandler);
    // this.element.removeEventListener("photo-upload:success", this.uploadSuccessHandler);
  }

  initSwiper() {
    this.swiper = new Swiper(this.sliderTarget, {
      modules: [Navigation],
      slidesPerView: "auto",
      spaceBetween: 30,
      freeMode: true,
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      },
    });
  }

  initGallery() {
    this.lightbox = new PhotoSwipeLightbox({
      gallery: this.element, // the gallery container (the element with this controller)
      children: "a", // the direct children anchors (for individual images)
      pswpModule: PhotoSwipe,
      bgOpacity: 0.9, // optional customization (background opacity)
      showHideAnimationType: "zoom",
    });

    this.lightbox.init();
  }

  onUploadComplete(event) {
    // console.log("upload complete", event);
    const frame = this.element.closest("turbo-frame");

    if (!frame.src) {
      frame.src = window.location.href;
    } else {
      frame.reload();
    }
  }

  onUploadSuccess(event) {
    const file = event.detail.file;

    // console.log("previewer upload success", file);

    const template = Handlebars.compile(this.imageTemplate());
    const html = template({
      width: file.meta.width,
      height: file.meta.height,
      url: file.uploadURL,
      thumbUrl: file.thumbnailURL,
      name: file.name,
    });
    this.sliderTarget.querySelector(".swiper-wrapper").insertAdjacentHTML("beforeend", html);

    // Update Swiper to recognize the new slide
    this.swiper.update();
  }

  imageTemplate() {
    return `
      <a data-pswp-width="{{width}}" data-pswp-height="{{height}}" class="p-2 !w-48 rounded-lg border border-base-300 swiper-slide bg-white cursor-zoom-in transition duration-300 hover:bg-base-200/75 hover:shadow" href="{{url}}">
        <img data-swiper-gallery-target="image" class="block object-cover w-48 h-48 rounded-lg" src="{{thumbUrl}}">
        <h6 class="p-2 mt-2 text-sm break-words font-medium">{{name}}</h6>
      </a>
    `;
  }
}
