import { StreamActions } from "@hotwired/turbo";
import Toastify from "toastify-js";
import Handlebars from "handlebars";

function htmlToNode(html) {
  var template = document.createElement("template");
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

function successTemplate() {
  return `
    <div class="inline-flex gap-2 items-center py-2 px-3 font-medium rounded-md bg-success text-success-content" data-turbo-temporary>
      <span class="text-white i-ph-check-circle-fill size-6"></span>
      {{{message}}}
    </div>`;
}

function errorTemplate() {
  return `
    <div class="inline-flex gap-2 items-center py-2 px-3 font-medium rounded-md bg-error text-error-content" data-turbo-temporary>
      <span class="text-white i-ph-warning-fill size-6"></span>
      {{{message}}}
    </div>`;
}

function getTemplate(severity) {
  switch (severity) {
    case "success":
      return successTemplate();
    case "error":
      return errorTemplate();
  }
}

function compileTemplate(message, severity) {
  const template = getTemplate(severity);
  var contentTemplate = Handlebars.compile(template);
  return contentTemplate({ message: message });
}

export function toast(message, severity, duration = 5000) {
  var content = compileTemplate(message, severity);

  Toastify({
    node: htmlToNode(content),
    duration: duration,
    close: false,
    gravity: "top", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      background: "transparent",
    },
  }).showToast();
}

StreamActions.toast = function () {
  const message = this.getAttribute("message");
  const severity = this.getAttribute("severity");
  const duration = parseInt(this.getAttribute("duration"));

  toast(message, severity, duration);
};
