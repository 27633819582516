// application.js
import * as Turbo from "@hotwired/turbo";
import TurboPower from "turbo_power";

TurboPower.initialize(Turbo.StreamActions);

import "./toast.js";
import "./close_dialog.js";
import "./scroll.js";
import "./reload_page.js";
import "./raise_event.js";
