import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle(e) {
    const button = e.currentTarget;
    const row = button.closest("tr");
    const isExpanded = button.getAttribute("aria-expanded") === "true";
    button.setAttribute("aria-expanded", !isExpanded);
    button.classList.toggle("row-expanded");

    const childRows = document.querySelectorAll(`[data-master-row='${row.id}']`);
    childRows.forEach((child) => {
      child.classList.toggle("hidden");
    });
  }
}
