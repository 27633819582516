import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";
import DragSort from "@yaireo/dragsort";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.tagify = new Tagify(this.inputTarget);

    this.dragsort = new DragSort(this.tagify.DOM.scope, {
      selector: "." + this.tagify.settings.classNames.tag,
      callbacks: {
        dragEnd: this.onDragEnd.bind(this),
      },
    });
  }

  // must update Tagify's value according to the re-ordered nodes in the DOM
  onDragEnd(elm) {
    this.tagify.updateValueByDOMTags();
  }
}
