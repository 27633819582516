import BaseController from "./base_controller.js";
import HSDropdown from "flyonui/dist/js/dropdown.js";

export default class extends BaseController {
  connect() {
    super.connect();
    this.initHSDropdown();
  }

  // preline plugin call its own autoInit() method on window "load" event which is not suitable
  // with rails turbo merchansim, so we disable it by adding --prevent-on-load-init class to root element
  // and manually handle preline dropdown init
  // the initHSDropdown is a part of autoInit() method
  // see https://github.com/htmlstreamofficial/preline/blob/4964f1e7a5ef08aeda2c3e1915962828e081cf7a/src/plugins/dropdown/index.ts#L250
  initHSDropdown() {
    if (!window.$hsDropdownCollection) window.$hsDropdownCollection = [];

    document.addEventListener("keydown", (evt) => HSDropdown.accessibility(evt));

    window.addEventListener("click", (evt) => {
      const evtTarget = evt.target;

      HSDropdown.closeCurrentlyOpened(evtTarget);
    });

    let prevWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      if (window.innerWidth !== prevWidth) {
        prevWidth = innerWidth;
        HSDropdown.closeCurrentlyOpened(null, false);
      }
    });

    this.hsDropdown = new HSDropdown(this.element);
  }

  prefix() {
    return "dropdown";
  }

  collection() {
    if (!window.$hsDropdownCollection) window.$hsDropdownCollection = [];

    return window.$hsDropdownCollection;
  }
}
