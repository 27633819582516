import BaseController from "./base_controller.js";
import HSComboBox from "flyonui/dist/js/combobox.js";

export default class extends BaseController {
  connect() {
    super.connect();
    this.initHSComboBox();
  }

  // preline plugin call its own autoInit() method on window "load" event which is not suitable
  // with rails turbo merchansim, so we disable it by adding --prevent-on-load-init class to root element
  // and manually handle preline dropdown init
  // the initHSDropdown is a part of autoInit() method
  // see https://github.com/htmlstreamofficial/preline/blob/4964f1e7a5ef08aeda2c3e1915962828e081cf7a/src/plugins/dropdown/index.ts#L250
  initHSComboBox() {
    if (!window.$hsComboBoxCollection) window.$hsComboBoxCollection = [];
    window.addEventListener("click", (evt) => {
      const evtTarget = evt.target;

      HSComboBox.closeCurrentlyOpened(evtTarget);
    });

    document.addEventListener("keydown", (evt) => HSComboBox.accessibility(evt));

    const data = this.element.getAttribute("data-combo-box");

    const options = data ? JSON.parse(data) : {};

    this.hsComboBox = new HSComboBox(this.element, options);
  }

  prefix() {
    return "combobox";
  }

  collection() {
    if (!window.$hsComboBoxCollection) window.$hsComboBoxCollection = [];

    return window.$hsComboBoxCollection;
  }
}
