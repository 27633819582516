import { StreamActions } from "@hotwired/turbo";

StreamActions.scroll_to_bottom = function () {
  const targetElement = document.getElementById(this.target);

  targetElement.scrollTo({
    top: targetElement.scrollHeight,
    behavior: "smooth",
  });
};
