import { application } from "./application.js";

import RailsNestedForm from "@stimulus-components/rails-nested-form";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import Timeago from "stimulus-timeago";
import ReadMore from "@stimulus-components/read-more";
import { Autocomplete } from "stimulus-autocomplete";

// 3rd party controllers
application.register("nested-form", RailsNestedForm);
application.register("textarea-autogrow", TextareaAutogrow);
application.register("timeago", Timeago);
application.register("read-more", ReadMore);
application.register("autocomplete", Autocomplete);
