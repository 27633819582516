import BaseController from "./base_controller.js";
import HSCollapse from "flyonui/dist/js/collapse.js";

export default class extends BaseController {
  // static targets = ["toggle"];

  connect() {
    super.connect();
    this.init();
  }

  init() {
    if (!window.$hsCollapseCollection) window.$hsCollapseCollection = [];

    this.hsCollapse = new HSCollapse(this.element);
  }

  prefix() {
    return "collapse";
  }

  collection() {
    if (!window.$hsCollapseCollection) window.$hsCollapseCollection = [];

    return window.$hsCollapseCollection;
  }
}
