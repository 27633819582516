import Handlebars from "handlebars";

function getTemplateHTML(templateId) {
  const templateElement = document.getElementById(templateId);

  if (templateElement) {
    return templateElement.innerHTML;
  } else {
    throw new Error(`Template ${templateId} not found!`);
  }
}

function compileTemplate(html, data) {
  const source = Handlebars.compile(html);
  return source(data);
}

function defaultSkeleton() {
  const template = Handlebars.compile(`
    <div class="t-loading flex justify-center items-center w-full h-60">
      <svg class="flex-none w-16 h-16 animate-spin text-base-200 bg-white" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
        <path d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-400"> </path>
      </svg>
    </div>
  `);
  return template({});
}

function getSkeletonHTML(skeletonId) {
  let skeleton;

  try {
    const skeletonHTML = getTemplateHTML(skeletonId);
    skeleton = compileTemplate(skeletonHTML, {});
  } catch (e) {
    skeleton = defaultSkeleton();
  }

  return skeleton;
}

function getModalHTML(spec) {
  const { size } = spec;
  const sizeClass = getModalSize(size);

  return `
    <div id="{{id}}" class="t-dialog" data-controller="dialog" data-type="modal" data-dialog-show-value="{{show}}" data-dialog-remove-on-close-value="true">
      <div data-dialog-target="container" class="hidden overflow-y-auto fixed inset-0 z-10">
        <div data-dialog-target="backdrop" class="fixed inset-0 t-backdrop bg-neutral-700/30"></div>
        <div class="flex justify-center items-center px-4 pt-4 pb-20 min-h-screen text-center transition-all transform duration-300">
          <turbo-frame id="{{frameId}}" src="{{href}}" refresh="morph" data-controller="modal-frame" data-dialog-target="dialog" class="inline-block text-left align-bottom bg-white rounded-lg shadow-xl w-full max-w-xl sm:my-8 align-middle ${sizeClass}">
            <div class="t-loading flex justify-center items-center w-full h-60">
              <svg class="flex-none w-16 h-16 animate-spin text-base-200 bg-white" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-400"> </path>
              </svg>
            </div>
          </turbo-frame>
        </div>
      </div>
    </div>
  `;
}

function getFullscreenHTML(spec) {
  return `
    <div id="{{id}}" class="t-dialog" data-controller="dialog" data-type="fullscreen" data-dialog-show-value="{{show}}" data-dialog-remove-on-close-value="true">
      <div data-dialog-target="container" class="hidden overflow-y-auto fixed inset-0 z-10">
        <div data-dialog-target="backdrop" class="fixed inset-0 bg-opacity-75 t-backdrop bg-base-300"></div>
        <div class="overflow-hidden absolute inset-0">
          <turbo-frame id="{{frameId}}" src="{{href}}" refresh="morph" data-dialog-target="dialog" class="fixed inset-2 bg-white rounded-lg shadow-xl sm:inset-16">
            <div class="t-loading flex justify-center items-center w-full h-60">
              <svg class="flex-none w-16 h-16 animate-spin text-base-200 bg-white" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-400"> </path>
              </svg>
            </div>
          </turbo-frame>
        </div>
      </div>
    </div>
  `;
}

function getSlideoverHTML(spec) {
  const { size } = spec;
  const sizeClass = getSlideoverSize(size);

  return `
    <div id="{{id}}" class="t-dialog" data-controller="dialog" data-dialog-show-value="{{show}}" data-dialog-remove-on-close-value="true" data-type="slideover">
      <div data-dialog-target="container" class="hidden overflow-hidden fixed inset-0 z-10">
        <div data-dialog-target="backdrop" class="fixed inset-0 bg-opacity-75 t-backdrop bg-base-300"></div>
        <div class="overflow-hidden absolute inset-0">
          <turbo-frame id="{{frameId}}" src="{{href}}" refresh="morph" data-dialog-target="dialog" class="fixed inset-y-2 right-2 w-full bg-white rounded-lg shadow-xl max-w-[calc(100%-16px)] ${sizeClass}">
            <div class="t-loading flex justify-center items-center w-full h-60">
              <svg class="flex-none w-16 h-16 animate-spin text-base-200 bg-white" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-400"> </path>
              </svg>
            </div>
          </turbo-frame>
        </div>
      </div>
    </div>
  `;
}

function getModalSize(size) {
  switch (size) {
    case "sm":
      return "sm:max-w-xl";
    case "md":
      return "md:max-w-2xl";
    case "lg":
      return "lg:max-w-4xl";
  }
}

function getSlideoverSize(size) {
  switch (size) {
    case "sm":
      return "sm:max-w-md";
    case "md":
      return "md:max-w-2xl";
    case "lg":
      return "xl:max-w-[80%] 2xl:max-w-screen-xl";
  }
}

function getDialogHTML(spec) {
  switch (spec.type) {
    case "slideover":
      return getSlideoverHTML(spec);
    case "fullscreen":
      return getFullscreenHTML(spec);
    default:
      return getModalHTML(spec);
  }
}

/**
 * spec = {
 *   id: "",
 *   show: true | false,
 *   size: "sm | md | lg",
 *   frameId: "",
 *   href: "",
 *   skeletonId: "",
 *   type: "slideover | fullscreen",
 * }
 *
 */
export function getDialogTemplate(spec) {
  const templateHTML = getDialogHTML(spec);
  return compileTemplate(templateHTML, spec);
}
