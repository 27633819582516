import BaseController from "./base_controller.js";
import HSSelect from "flyonui/dist/js/select.js";

export default class extends BaseController {
  static targets = ["input"];

  connect() {
    super.connect();
    this.initHSSelect();
  }

  initHSSelect() {
    if (!window.$hsSelectCollection) window.$hsSelectCollection = [];

    window.addEventListener("click", (evt) => {
      const evtTarget = evt.target;

      HSSelect.closeCurrentlyOpened(evtTarget);
    });

    document.addEventListener("keydown", (evt) => HSSelect.accessibility(evt));

    this.hsSelect = new HSSelect(this.inputTarget);

    this.hsSelect.on("change", (val) => {
      this.dispatch("change", { detail: { value: val, el: this.hsSelect.el } });
    });
  }

  prefix() {
    return "select";
  }

  collection() {
    if (!window.$hsSelectCollection) window.$hsSelectCollection = [];

    return window.$hsSelectCollection;
  }
}
