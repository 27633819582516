import { Controller } from "@hotwired/stimulus";
import EditorJS from "@editorjs/editorjs";
import Table from "@editorjs/table";

export default class extends Controller {
  connect() {
    this.editor = new EditorJS({
      holder: this.element,
      placeholder: "Type text or paste a link",
      tools: {
        table: Table,
      },
    });
  }
}
