import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  load(e) {
    const value = e.detail.value;
    const url = this.element.dataset.url.replace(":id", value);
    get(url, { responseKind: "turbo-stream" });
  }
}
