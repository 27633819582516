import UploadController from "./upload_controller";
import Handlebars from "handlebars";
import { toast } from "../stream_actions/toast";
import { merge } from "lodash";

export default class extends UploadController {
  connect() {
    super.connect();
  }

  _getFileTypes() {
    return ["image/*"];
  }

  _getMaxFiles() {
    return 1;
  }

  _handleUploadSuccess(file, response) {
    toast(`File ${file.name} uploaded successfully!`, "success");

    const uploadedFile = merge(file, response.body);

    const coverTemplate = document.getElementById("cover-template");

    const source = Handlebars.compile(coverTemplate.innerHTML);
    const html = source({ url: uploadedFile.uploadURL });

    this.previewer.innerHTML = html;
  }
}
