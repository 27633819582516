import BaseController from "./base_controller.js";
import HSTabs from "flyonui/dist/js/tabs.js";

export default class extends BaseController {
  connect() {
    super.connect();
    this.init();
  }

  init() {
    if (!window.$hsTabsCollection) window.$hsTabsCollection = [];

    this.hsTabs = new HSTabs(this.element);
  }

  prefix() {
    return "tabs";
  }

  collection() {
    if (!window.$hsTabsCollection) window.$hsTabsCollection = [];

    return window.$hsTabsCollection;
  }
}
