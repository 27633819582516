import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    format: { type: String, default: "F j, Y" },
  };

  connect() {
    flatpickr(this.inputTarget, {
      altInput: true,
      altFormat: this.formatValue,
      dateFormat: "Y-m-d",
      static: true,
      // positionElement: this.element,
    });
  }
}
