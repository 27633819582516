import { Controller } from "@hotwired/stimulus";

// <button data-controller="dispatcher"
//         data-action="dispatcher#dispatch"
//         data-receiver="dom-id"
//         data-event="event-name"
//         data-detail="{}">
export default class extends Controller {
  dispatch(e) {
    const { receiver, event, detail } = e.currentTarget.dataset;
    const targetElement = receiver ? document.getElementById(receiver) : document;
    const eventDetail = JSON.parse(detail);

    targetElement.dispatchEvent(
      new CustomEvent(event, {
        bubbles: false,
        cancelable: true,
        detail: eventDetail,
      }),
    );
  }
}
