import { Controller } from "@hotwired/stimulus";
import { computePosition, autoUpdate, offset, flip, shift } from "@floating-ui/dom";
import { useClickOutside } from "stimulus-use";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["content"];
  static values = { show: Boolean };

  connect() {
    this.showing = this.showValue;
    this.anchor = document.getElementById(this.element.dataset.anchor);
    this.content = this.contentTarget;
    this.placement = this.element.dataset.placement || "right-start";
    this.portal = document.getElementById(this.element.dataset.portal) || document.body;
    this.offsetValue = this.element.dataset.offset || 8;
    this.originalParent = this.contentTarget.parentNode;

    useClickOutside(this, { element: this.content });

    if (!window.floatingStacks) {
      window.floatingStacks = [];
    }

    if (!this.anchor) {
      throw new Error(`Floating anchor not found! Please ensure the id correctly passed.`);
    }

    this.showFloatingHandler = this.show.bind(this);
    this.hideFloatingHandler = this.hide.bind(this);
    this.toggleFloatingHandler = this.toggle.bind(this);

    this.element.addEventListener("floating:show", this.showFloatingHandler);
    this.element.addEventListener("floating:hide", this.hideFloatingHandler);
    this.element.addEventListener("floating:toggle", this.toggleFloatingHandler);

    if (this.showing) this.show();
  }

  disconnect() {
    this.element.removeEventListener("floating:show", this.showFloatingHandler);
    this.element.removeEventListener("floating:hide", this.hideFloatingHandler);
    this.element.removeEventListener("floating:toggle", this.toggleFloatingHandler);
  }

  toggle() {
    if (this.showing) {
      this.hide();
    } else {
      this.show();
    }
  }

  addToStack() {
    window.floatingStacks.push(this);
  }

  removeFromStack() {
    window.floatingStacks = window.floatingStacks.filter((floating) => floating !== this);
  }

  isTopFloating() {
    return window.floatingStacks[window.floatingStacks.length - 1] === this;
  }

  show() {
    if (this.showing) return;

    this.showing = true;

    this.addToStack();

    // Teleport to portal, ensure the menu always appear on top of other elements
    this.portal.appendChild(this.content);
    gsap.set(this.content, { display: "block", width: "0%" });

    this.updatePosition();

    gsap.to(this.content, {
      opacity: 1,
      width: "auto",
      duration: 0.3,
      // onComplete: () => {
      //   this.bindOutsideClick();
      // },
    });
  }

  hide() {
    if (!this.showing) return;

    this.showing = false;

    this.removeFromStack();
    // this.unbindOutsideClick();

    gsap.to(this.content, {
      opacity: 0,
      width: "0%",
      duration: 0.3,
      onComplete: () => {
        gsap.set(this.content, { display: "none" });

        // Revert menu back to original parent
        this.originalParent.appendChild(this.content);
      },
    });
  }

  updatePosition() {
    computePosition(this.anchor, this.content, {
      placement: this.placement,
      middleware: [offset(this.offsetValue), flip(), shift()],
    }).then(({ x, y }) => {
      Object.assign(this.content.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  clickOutside(event) {
    if (!this.anchor.contains(event.target) && this.isTopFloating()) {
      event.preventDefault();
      this.hide();
    }
  }
}
