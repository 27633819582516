import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("radio-hint-selection");
  }

  select(e) {
    this.element.querySelectorAll(".input-hint").forEach((inputHint) => {
      inputHint.classList.add("hidden");
    });

    const radioInput = e.target.closest(".radio-input");
    const hint = radioInput.querySelector(".input-hint");
    hint.classList.remove("hidden");
  }
}
