import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
import { put } from "@rails/request.js";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["indicator"];

  connect() {
    useIntersection(this, {
      threshold: 0.7,
    });
  }

  appear(entry, observer) {
    this.startReadTimer();
  }

  disappear(entry, observer) {
    this.clearReadTimer();
    // callback automatically triggered when the element
    // leaves the viewport (or root Element specified in the options)
  }

  startReadTimer() {
    this.readTimer = setTimeout(() => {
      this.markAsRead();
    }, 5000);
  }

  clearReadTimer() {
    clearTimeout(this.readTimer);
  }

  markAsRead() {
    const markReadUrl = this.element.dataset.markReadUrl;
    const unread = this.element.dataset.unread;

    if (unread) {
      const timeline = gsap.timeline({
        onComplete: () => {
          if (this.hasIndicatorTarget) {
            this.indicatorTarget.remove();
            this.element.dataset.unread = false;

            // sync to server
            put(markReadUrl, { responseKind: "turbo-stream" });
          }
        },
      });
      timeline.to(this.element, { background: "transparent", duration: 0.5 });

      if (this.hasIndicatorTarget) {
        timeline.to(this.element, { background: "transparent", duration: 0.5 }, "<");
      }
    }
  }
}
