import { Controller } from "@hotwired/stimulus";
// import { createFocusTrap } from "focus-trap";
import { useClickOutside } from "stimulus-use";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["container", "backdrop", "dialog"];
  static values = {
    show: { type: Boolean, default: false },
    zIndex: Number,
    removeOnClose: { type: Boolean, default: false },
  };

  connect() {
    this.showing = this.showValue;
    this.type = this.element.dataset.type || "modal";

    if (!window.dialogStacks) {
      window.dialogStacks = [];
    }
    document.addEventListener("click", this.handleClickOutside);

    useClickOutside(this, { element: this.dialogTarget });

    // this.focusTrap = createFocusTrap(this.dialogTarget, {
    //   escapeDeactivates: false,
    //   allowOutsideClick: true,
    // });
    this.escapeKeyHandler = this.onEscapeKey.bind(this);
    this.zIndexValue = this.zIndexValue || 100;

    this.showDialogHandler = this.show.bind(this);
    this.hideDialogHandler = this.hide.bind(this);
    this.toggleDialogHandler = this.show.bind(this);

    this.element.addEventListener("dialog:show", this.showDialogHandler);
    this.element.addEventListener("dialog:hide", this.hideDialogHandler);
    this.element.addEventListener("dialog:toggle", this.toggleDialogHandler);

    if (this.showing) {
      this.show();
    }
  }

  disconnect() {
    this.element.removeEventListener("dialog:show", this.showDialogHandler);
    this.element.removeEventListener("dialog:hide", this.hideDialogHandler);
    this.element.removeEventListener("dialog:toggle", this.toggleDialogHandler);
    document.removeEventListener("click", this.handleClickOutside);
  }

  toggle() {
    if (this.showing) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.showing = true;

    this.addToStack();
    document.addEventListener("keydown", this.escapeKeyHandler);

    this.zIndexValue = 100 + window.dialogStacks.length * 2;
    this.containerTarget.classList.remove("hidden");
    this.containerTarget.style.zIndex = this.zIndexValue;
    this.lockScroll();

    const timeline = gsap.timeline({
      onComplete: () => {
        // this.focusTrap.activate();
        // Dispatch a custom event once the animation finishes
        this.dispatch("on-show", { detail: { dialog: this, action: "show" } });
      },
    });

    const animation = this.animations[this.type];
    timeline.fromTo(this.backdropTarget, { opacity: 0 }, { opacity: 1, duration: 0.3 });
    timeline.fromTo(this.dialogTarget, animation.enter.from, animation.enter.to, "<");
  }

  hide() {
    this.showing = false;

    this.removeFromStack();
    document.removeEventListener("keydown", this.escapeKeyHandler);

    const timeline = gsap.timeline({
      onComplete: () => {
        this.zIndexValue = 100;
        this.containerTarget.classList.add("hidden");
        // this.focusTrap.deactivate();
        this.unlockScroll();

        if (this.removeOnCloseValue) {
          this.element.remove();
        }

        this.dispatch("on-hide", { detail: { dialog: this, action: "hide" } });
      },
    });

    const animation = this.animations[this.type];
    timeline.fromTo(this.backdropTarget, { opacity: 1 }, { opacity: 0, duration: 0.3 });
    timeline.fromTo(this.dialogTarget, animation.exit.from, animation.exit.to, "<");
  }

  get animations() {
    return {
      modal: {
        enter: {
          from: { opacity: 0, y: "100%" },
          to: { opacity: 1, y: "0%", duration: 0.3 },
        },
        exit: {
          from: { opacity: 1 },
          to: { opacity: 0, duration: 0.3 },
        },
      },
      slideover: {
        enter: {
          from: { opacity: 0, x: "100%" },
          to: { opacity: 1, x: "0%", duration: 0.3 },
        },
        exit: {
          from: { opacity: 1, x: "0%" },
          to: { opacity: 0, x: "100%", duration: 0.3 },
        },
      },
      prompt: {
        enter: {
          from: { opacity: 0 },
          to: { opacity: 1, duration: 0.3 },
        },
        exit: {
          from: { opacity: 1 },
          to: { opacity: 0, duration: 0.3 },
        },
      },
      fullscreen: {
        enter: {
          from: { opacity: 0, y: "100%" },
          to: { opacity: 1, y: "0%", duration: 0.3 },
        },
        exit: {
          from: { opacity: 1 },
          to: { opacity: 0, duration: 0.3 },
        },
      },
    };
  }

  clickOutside(event) {
    // click on file input inside dialog should not close the dialog
    // the trix editor creates a hidden input file outside of dialog
    if (event.target.tagName === "INPUT" && event.target.type === "file") {
      return;
    }

    if (this.isTopDialog()) {
      event.preventDefault();
      this.hide();
    }
  }

  onEscapeKey(event) {
    if (event.key === "Escape" && this.isTopDialog()) {
      event.preventDefault();
      this.hide();
    }
  }

  addToStack() {
    window.dialogStacks.push(this);
  }

  removeFromStack() {
    window.dialogStacks = window.dialogStacks.filter((dialog) => dialog !== this);
  }

  isTopDialog() {
    return window.dialogStacks[window.dialogStacks.length - 1] === this;
  }

  lockScroll() {
    document.body.style.overflow = "hidden";
  }

  unlockScroll() {
    if (window.dialogStacks.length === 0) {
      document.body.style.overflow = "";
    }
  }
}
