import { Controller } from "@hotwired/stimulus";
import Inputmask from "inputmask";

export default class extends Controller {
  static values = {
    options: Object,
  };

  connect() {
    Inputmask(this.optionsValue).mask(this.element);
  }
}
