import BaseController from "./base_controller.js";
import HSPinInput from "flyonui/dist/js/pin-input.js";

export default class extends BaseController {
  static targets = ["input", "control"];

  connect() {
    super.connect();
    this.init();
  }

  init() {
    if (!window.$hsPinInputCollection) window.$hsPinInputCollection = [];

    this.hsPinInput = new HSPinInput(this.controlTarget);

    this.hsPinInput.on("completed", ({ currentValue }) => {
      this.inputTarget.value = currentValue.join("");
    });
  }

  prefix() {
    return "pin-input";
  }

  collection() {
    if (!window.$hsPinInputCollection) window.$hsPinInputCollection = [];

    return window.$hsPinInputCollection;
  }
}
