import { Controller } from "@hotwired/stimulus";
import { createAvatar } from "@dicebear/core";
import { initials, shapes } from "@dicebear/collection";

export default class extends Controller {
  static values = {
    name: String,
    circle: { type: Boolean, default: true },
    initials: { type: Boolean, default: true },
    size: { type: Number, default: 32 },
  };

  connect() {
    const chars = this.nameValue.split(" ").length;
    const type = this.initialsValue ? initials : shapes;
    const avatar = createAvatar(type, {
      seed: this.nameValue,
      size: this.sizeValue,
      radius: this.circleValue ? 50 : 10,
      fontWeight: 500,
      chars: chars > 1 ? 2 : 1,
    });
    const svg = avatar.toString();
    this.element.innerHTML = svg;
  }
}
